import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  rootCreditExplanation: {
    margin: '50px 0 20px 0',
    padding: '30px', 
    background: 'transparent', 
    borderRadius: '12px', 
  },
  titleCreditExplanation: {
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    lineHeight: '48px', 
    fontWeight: 700,
    color: '#00DD9E',
    textAlign: 'left',
    marginBottom: '20px', 
    [theme.breakpoints.down('sm')]: {
      fontSize: '36px',
      lineHeight: '42px',
    },
  },
  listContainer: {
    padding: 0,
  },
  listItem: {
    padding: '12px 0',
  },
  descriptionCreditExplanation: {
    fontFamily: 'MontserratLight',
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: '32px', 
    letterSpacing:'0em',
    color: theme.palette.text.tertiary,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '28px',
    },
  },
}));

export default useStyles;
