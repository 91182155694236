import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  breadcrumbContainer: {
    marginTop: 75,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom:-90,
  },
  fontLink: {
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontStyle: 'normal',
    fontSize: '16px',
    lineHeight: '26px',
  },
  root: {
    marginTop: '100px',
    marginBottom: '50px',
  },
  projectTitle: {
    fontFamily: 'MontserratBold',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '50px',
    lineHeight: '63px',
    color: '#00DD9E;',
    marginBottom: '13px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'left',
      textAlign: 'left',
    },
  },
  countryText: {
    fontFamily: 'Montserrat',
    fontWeight: '700',
    fontStyle: 'normal',
    fontSize: '30px',
    lineHeight: '36px',
    color: theme.palette.text.secondary,
    marginBottom: '16px',
    display: 'flex',
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'left',
      textAlign: 'left',
    },
  },
  descriptionText: {
    color: theme.palette.text.tertiary,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '400',
    fontStyle: 'normal',
    alignItems: 'center',
    marginBottom: '38px',
    whiteSpace: 'normal', 
    textAlign: 'left',
    display: 'inline', 
  },
  customLink: {
    textDecoration: 'none',
    color: '#00DD9E',
    fontWeight: 'bold',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionOtherProjects: {
    fontFamily: 'Montserrat',
    fontWeight: '800',
    fontStyle: 'normal',
    fontSize: '40px',
    lineHeight: '54px',
    color: theme.palette.text.secondary,
    justifyContent: 'left',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      marginBottom: '34px',
      paddingTop: '2px',
    },
  },

 
  locationFootNote: {
    fontFamily: 'MontserratLight',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '26px',
    letterSpacing:'0em',
    color: theme.palette.text.tertiary,
    textAlign: 'left',
    marginBottom: 0,
  },
  locationText: {
    fontFamily: 'MontserratLight',
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: '32px', 
    letterSpacing:'0em',
    color: theme.palette.text.tertiary,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      lineHeight: '28px',
    },
    marginBottom: 50,
  
  },
 
  imageGallery: {
    flexDirection: 'column',
    marginTop: 4,
    width: '100%',
    '& img ': {
      borderRadius: '15px',
    },

    '& .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus': {
      borderRadius: '17px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 24,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },

  image: {
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'contain',
  },

  link: {
    textDecoration: 'none',
  },
  projectContainer: {
    marginTop: '10px',
    marginBottom: '40px',
  },

  chartDmrv: {
    background: '#fff',
    borderRadius: '12px',
    padding: 10,
  },
  containerAboutEgreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    padding: '20px',
  },
  titleIPFS: {
    marginBottom: '40px',
    color: '#00DD9E',
    fontFamily: 'MontserratBold',
    fontSize: '50px',
    lineHeight: '63px',
    textAlign: 'left',
  },

  contentButtonProjectDetail: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  fromProject: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
 
  containerTextDocumentation: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '20px',
    },
  },
  imageGalleryThumbnail: {
    objectFit: 'cover',
    borderRadius: '18px', 
    transition: 'border 0.3s ease-in-out', 
  },

documentProjectDetail: {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
},
 
}));

export default useStyles;
